import React, { useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';

import Disclaimer from '../Disclaimer/Disclaimer';

import './Response.scss';

const ResponseNotifier = ({ answer, description, onNewSearch }) => {
  const [toggleDisclaimer, setToggleDisclaimer] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  // Toggles disclaimer modal.
  const handleDisclaimer = () => {
    if (toggleDisclaimer === true) {
      setTimeout(() => setShowDisclaimer(!showDisclaimer), 700);
    } else {
      setShowDisclaimer(!showDisclaimer);
    }
    setToggleDisclaimer(!toggleDisclaimer);
  };

  return (
    <section className="response-section">
      <div>
        {answer === 'sfw' ? (
          <i className="fas fa-check-circle success"></i>
        ) : null}
        {answer === 'nsfw' ? (
          <i className="fas fa-exclamation-triangle alert"></i>
        ) : null}
        {answer === 'error' ? (
          <i className="fas fa-exclamation-circle error"></i>
        ) : null}
      </div>
      <div className="response-text">
        <p>{description}</p>
      </div>
      <div className="response-new-search">
        <CustomButton onClick={onNewSearch}>New Search</CustomButton>
      </div>
      {answer === 'sfw' ? (
        <div className="response-new-search">
          <i
            className="fas fa-exclamation-circle disclaimer-icon"
            onClick={handleDisclaimer}
          ></i>
          {showDisclaimer ? (
            <Disclaimer
              handleDisclaimer={handleDisclaimer}
              toggleDisclaimer={toggleDisclaimer}
            />
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
    </section>
  );
};

export default ResponseNotifier;
