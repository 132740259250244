import React from 'react';

import './DisclaimerItem.scss';

const DisclaimerItem = () => {
  return (
    <div className="disclaimer-item">
      <i className="fas fa-times window-close"></i>
      <p>
        <span className="disclaimer-bold">Disclaimer: </span>
        <br />
        Each website displays images differently and can return safe-for-work if
        our checker can’t pick them up.{' '}
      </p>
      <br />
      <p>
        <span className="disclaimer-bold">
          Please visit each URL with caution.{' '}
        </span>
        <br />
        We are constantly making improvements to our search criteria daily to
        provide more accurate results.
      </p>
    </div>
  );
};

export default DisclaimerItem;
