import React from 'react';
import FadeIn from 'react-fade-in';
import ReactLoading from 'react-loading';

import './Loading.scss';

const Loading = ({ theme }) => {
  return (
    <div className="loading-spin">
      <FadeIn>
        <ReactLoading
          type={'spin'}
          color={theme === 'light' ? 'red' : 'white'}
        />
        <p>Checking. This may take a few moments...</p>
      </FadeIn>
    </div>
  );
};

export default Loading;
