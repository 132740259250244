import React, { useState } from 'react';

import EmptySubmit from '../EmptySubmit/EmptySubmit';

import './Search.scss';

const Search = ({
  onInputChange,
  onButtonSubmit,
  input,
  clearInput,
  handleHttpInputAddition,
  emptySubmit,
}) => {
  const [focus, setFocus] = useState(false);

  const handleCursor = () => {
    // Put cursor position towards the end of input value automatically
    // when cursor has default 'http://' value.
    if (input === 'http://') {
      const inputEl = document.getElementById('main-search');
      inputEl.focus();
      inputEl.setSelectionRange(input.length, input.length);
    }
  };

  const handleOnFocus = () => {
    if (input.length === 0) {
      handleHttpInputAddition();
    }
    setFocus(true);
  };

  const handleOnBlur = () => {
    // If input only contains 'http://' which is the default clear
    // the input once we leave focus.
    if (input === 'http://') {
      clearInput();
    }
    setFocus(false);
  };

  return (
    <React.Fragment>
      <div
        className="search-container"
        style={emptySubmit ? inputAnimation : {}}
      >
        <i className={`fas fa-search ${focus ? 'focus' : ''} search-icon`}></i>
        <input
          id="main-search"
          autoComplete="off"
          className={`${input ? 'show-search-text' : 'hide-search-text'}`}
          type="text"
          onClick={handleCursor}
          placeholder="https://www.google.com"
          onChange={onInputChange}
          onKeyDown={onButtonSubmit}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={input}
        />
        <i
          className="fas fa-times-circle cancel-search-icon"
          onClick={clearInput}
          style={{
            visibility: input ? 'visible' : 'hidden',
            animation: `${input ? 'fadeIn' : 'fadeOut'} 1s`,
          }}
        ></i>
      </div>
      {emptySubmit ? <EmptySubmit emptySubmit={emptySubmit} /> : ''}
    </React.Fragment>
  );
};

const inputAnimation = {
  animation: `shake 0.82s cubic-bezier(.36,.07,.19,.97) both`,
  backfaceVisibility: `hidden`,
  transform: `translate3d(0, 0, 0)`,
  perspective: `1000px`,
};

export default Search;
