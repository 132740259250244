import React from 'react';
import './Logo.scss';

const Logo = () => {
  return (
    <header>
      <h1>
        <span className="logo-left">NSFW</span>
        <span className="logo-right">check</span>
      </h1>
    </header>
  );
};

export default Logo;
