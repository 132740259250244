import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in';
import Logo from './components/Logo/Logo';
import Tagline from './components/Tagline/Tagline';
import Search from './components/Search/Search';
import Response from './components/Response/Response';
import Footer from './components/Footer/Footer';
import Theme from './components/Theme/Theme';
import Loading from './components/Loading/Loading';

import './App.scss';

const INITIAL_STATE = {
  input: '',
  answer: '',
  description: '',
  hasResponse: false,
  loading: false,
  serverError: false,
};

const App = () => {
  const [appState, setAppState] = useState(INITIAL_STATE);
  const [isFetching, setIsFetching] = useState(false);
  const [theme, setTheme] = useState('light');
  const [emptySubmit, setEmptySubmit] = useState(false);
  const { input, answer, description, hasResponse, loading } = appState;

  // Fetch an answer from backend with the passed in url.
  useEffect(() => {
    async function fetchAnswer(url) {
      try {
        setAppState({ ...appState, loading: true });
        const response = await fetch(url, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            check: input,
          }),
        });
        const decision = await response.json();
        setAppState({
          ...appState,
          ...decision,
          hasResponse: true,
          loading: false,
        });
      } catch (error) {
        setAppState({
          ...appState,
          answer: 'error',
          description: 'There was an error. Please try again later.',
          hasResponse: true,
          loading: false,
          serverError: true,
        });
      }
    }

    if (isFetching) {
      fetchAnswer('/check');
      setIsFetching(false);
    }
  }, [isFetching, appState, input]);

  // Run when component is mounted and once and get the theme
  // if it's stored in local storage.
  useEffect(() => {
    if (localStorage.getItem('theme')) {
      setTheme(localStorage.getItem('theme'));
    }
  }, []);

  // Changes theme and store new theme to local storage if updated.
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  //
  const onURLChange = (data) => {
    let value = data.target.value;

    // If a user deletes http:// or https:// reset the input state back with http:// in it.
    if (
      value === 'http:' ||
      value === 'http:/' ||
      value === 'https:' ||
      value === 'https:/'
    ) {
      return handleHttpInputAddition();
    }

    setAppState({ ...appState, input: value });

    // If the value includes http or http twice as well as data:image links
    // slice the value from the end of the default 'http://' place holder.
    // Which would be 7 characters from the start of 'http://'.
    if (
      value.includes('http://http://') ||
      value.includes('http://https://') ||
      value.includes('http://data:image')
    ) {
      setAppState({ ...appState, input: value.slice(7) });
    }
  };

  const onInputChange = (event) => {
    onURLChange(event);
  };

  // If the event equals 'Enter' start fetching.
  const onButtonSubmit = (event) => {
    if (event.key === 'Enter' && input !== 'http://' && input !== 'https://') {
      setIsFetching(true);
    }

    if (
      event.key === 'Enter' &&
      (input === 'http://' || input === 'https://')
    ) {
      setEmptySubmit(true);
      setTimeout(() => setEmptySubmit(false), 1800);
    }
  };

  // Sets Apps State to the INITIAL_STATE.
  const onNewSearch = () => {
    setAppState(INITIAL_STATE);
  };

  // Sets App Theme.
  const switchTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  // Clears input state.
  const clearInput = () => {
    setAppState({ ...appState, input: '' });
  };

  // Adds 'http://' to input state.
  const handleHttpInputAddition = () => {
    setAppState({ ...appState, input: `http://` });
  };

  return (
    <div className="main-container">
      <FadeIn>
        <Logo />
      </FadeIn>
      {loading ? (
        <Loading theme={theme} />
      ) : hasResponse ? (
        <FadeIn>
          <Response
            answer={answer}
            description={description}
            hasResponse={hasResponse}
            onNewSearch={onNewSearch}
          />
        </FadeIn>
      ) : (
        <React.Fragment>
          <FadeIn>
            <Tagline />
            <Search
              onInputChange={onInputChange}
              onButtonSubmit={onButtonSubmit}
              input={input}
              handleHttpInputAddition={handleHttpInputAddition}
              clearInput={clearInput}
              emptySubmit={emptySubmit}
            />
            <Footer>
              <Theme switchTheme={switchTheme} theme={theme} />
            </Footer>
          </FadeIn>
        </React.Fragment>
      )}
    </div>
  );
};

export default App;
