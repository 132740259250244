import React from 'react';

import './Footer.scss';

const Footer = ({ children }) => {
  return (
    <footer>
      <span>
        &copy; 2020 <span className="logo-left">NSFW</span>
        <span className="logo-right">check.</span>
      </span>
      {children}
    </footer>
  );
};

export default Footer;
