import React from 'react';
import './Tagline.scss';

const Tagline = () => {
  return (
    <div className="tagline">
      <p>Search any website & see if it displays NSFW images.</p>
    </div>
  );
};

export default Tagline;
