import React from 'react';
import FadeIn from 'react-fade-in';

import DisclaimerItem from '../DisclaimerItem/DisclaimerItem';

import './Disclaimer.scss';

const Disclaimer = ({ handleDisclaimer, toggleDisclaimer }) => {
  return (
    <section
      className="disclaimer"
      style={{
        animation: `${toggleDisclaimer ? 'fadeIn' : 'fadeOut'} 1s`,
      }}
      onClick={handleDisclaimer}
    >
      <FadeIn>
        <DisclaimerItem />
      </FadeIn>
    </section>
  );
};

export default Disclaimer;
