import React from 'react';

import './EmptySubmit.scss';

const EmptySubmit = ({ emptySubmit }) => {
  return (
    <div
      className="empty-submit"
      style={{
        animation: `${emptySubmit ? 'fadeIn' : 'fadeOut'} .5s`,
      }}
    >
      <p>Please enter valid url.</p>
    </div>
  );
};

export default EmptySubmit;
